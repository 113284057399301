body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.g-card {
  margin: 20px;
  padding-bottom: 100px;
  transition: transform 0.5s ease;
}

.g-card:hover {
  transform: scale(1.08);
}

.g-card-image {
  border-radius: 10px;
  width: 300px;
  height: 480px;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.g-card-info {
  margin-top: 10px;
  min-height: 100px;
  scale: 110%;
}

.g-card-title {
  font-size: 24px;
  margin: 0px;
}

.g-card-sub-title {
  font-size: 16px;
  margin: 0px;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.err-msg {
  color: #ee6352;
  padding: 10px 15px;
}

.centerdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img {
  margin-top: 20px;
  border-radius: 10px;
  width: 720px;
  height: 1080px;
  object-fit: contain;
  box-shadow: 0px 0px 3px 1px #ccc;
}

.about-img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgb(75, 75, 75);
  opacity: 0.3;
}

.p-title {
  font-size: 42px;
  margin-top: 10px;
  margin-left: 90px;
  font-weight: bold;
}
.div-title {
  display: flex;
  justify-content: left;
  align-items: left;
}
